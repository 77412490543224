@import _/export
// ------------------------------
// ︿(￣︶￣)︿ 美食弹窗
// ------------------------------
.foodpopup,.videopopup
    +fix(transform,scale(0))
    +fix(transition,all 0.5s)
    display: none\0
    position: fixed
    top: 0
    right: 0
    width: 100%
    height: 100%
    z-index: 1000
    +bgo(0.3)
    &.on 
        +fix(transform,none)
        display: block\0
    .popmain
        position: absolute
        top: 200px
        left: 50%
        margin-left: -300px
        background-color: #FFF
        width: 600px
        padding-top: 50px
    .foodimg
        text-align: center
    .foodname
        font-size: 24px
        color: #333
        text-align: center
        padding: 10px 0
    .popclose
        position: absolute
        top: 20px
        right: 20px
        +bg(close,center)
        width: 30px
        height: 30px
        cursor: pointer
.videopopup
    .popmain
        padding: 30px
        padding-top: 55px
    .playbox
        height: 400px

.foodpopup2
    .popmain
        width: 680px
        margin-left: -340px
        top: 50%
        +fix(transform,translate(0,-50%))
    .foodimg
        padding: 40px
        padding-top: 30px
// ------------------------------
// ︿(￣︶￣)︿ 资料下载
// ------------------------------
.popup
    +fix(transform,scale(0))
    +fix(transition,all 0.5s)
    display: none\0
    position: fixed
    top: 0
    right: 0
    width: 100%
    height: 100%
    z-index: 1000
    +bgo(0.3)
    &.on 
        +fix(transform,none)
        display: block\0
    .popupcc
        position: absolute
        width: 800px
        left: 50%
        top: 50%
        margin-top: -168px
        margin-left: -400px
        height: 337px
        background-color: #FFF
        +br(3px)
        padding: 0 40px
        padding-top: 35px
    .popupti
        border-bottom: 1px solid $line
        font-size: 0
        h5 
            font-size: 16px
            color: $main
            border-bottom: 1px solid $main
            display: inline-block
            margin-bottom: -1px
            line-height: 30px
    .form-verity
        padding-top: 40px
        width: 298px
        margin: 0 auto
    .form-line
        margin-bottom: 15px
    .in-box.fl
        width: 126px
    input 
        width: 100%
        height: 47px
        border: 1px solid $line
        padding: 0 10px
    .getyzm
        width: 160px
        background-color: $second
        color: $main
        border: none
        +br(3px)
    .submit
        background-color: $main
        color: $second
        border: none
    .popclose
        position: absolute
        top: 20px
        right: 20px
        +bg(close,center)
        width: 30px
        height: 30px
        cursor: pointer
// ------------------------------
// ︿(￣︶￣)︿ 手机
// ------------------------------

@media screen and (max-width: 768px)
    .foodpopup,.videopopup 
        .popmain
            top: 50%
            width: 90%
            margin-left: 0

            +fix(transform,translate(-50%,-50%))
        .popclose
            top: 10px
            right: 10px
        .foodname
            font-size: 20px
    .videopopup 
        .popmain
            padding: 15px
            padding-top: 50px
            .playbox
                height: 300px
    .aboutpic 
        .videobox:before
            background-size: 40px auto
    .popup 
        .popupcc
            top: 50%
            width: 90%
            margin-left: 0
            margin-top: 0
            +fix(transform,translate(-50%,-50%))
            padding: 20px
        .popclose
            top: 10px
            right: 10px
            background-size: 15px auto
