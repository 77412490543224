//==============================================
// @var
//==============================================


$basefz: 14px // 全局字号
$baselh: 1.5 // 全局行高
$basecolor: #666 // 全局字体颜色

$main: #730000 // 主色
$second: #fcd7a1 // 次色
$error: #f00 // 错误
$warning: #ff0 // 警告
$success: #71b83d // 成功
$line: #e7e7e7 // 线条

$pink: #f08
$blue: #88f
$purple: #80f
$gray: #ccc
$red: $error

// 字体
$yh     : "microsoft YaHei"
$st     : "SimSun"
$xst    : "NSimSun"
$ht     : "SimHei"
$kt     : "KaiTi"


// 宽度
$w: 1200px

// 固定在底部
$footer: 0

// 统一间距
$m: 20px // margin
$p: 20px // padding

// ++++++  form  ++++++++
$form-height: 32px // 输入框高度
$form-textarea-height: 100px // 文本域高度
$required: $error


// ++++++  table  ++++++++
$table-bg: #FBFBFB

